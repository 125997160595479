<template>
  <div>
    <el-form :model="form"
             ref="formRef"
             label-width="100px">
      <el-row :gutter="10"
              style="padding-bottom:10px">
        <el-col :span="6">
          <el-form-item label="关键词">
            <el-input placeholder="ID、用户名、手机"
                      style="width:100%"
                      v-model="form.keyword" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="开始时间">
            <el-date-picker v-model="form.begin_time"
                            type="datetime"
                            style="width:100%"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="选择开始时间">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="截止时间">
            <el-date-picker v-model="form.end_time"
                            type="datetime"
                            default-time="23:59:59"
                            style="width:100%"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="选择截止时间" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-button type="primary"
                     @click="search">提交</el-button>
          <el-button type="primary"
                     @click="exportExcel">导出</el-button>
        </el-col>
      </el-row>
    </el-form>
    <!-- <Table ref="TablesRef"
           :tableData="tableData" /> -->

    <el-table border
              stripe
              highlight-current-row
              :max-height="$store.state.tableHeight + 45"
              :summary-method="getSummaries"
              :data="tableData"
              show-summary
              style="width: 100%">
      <el-table-column prop="id"
                       align="center"
                       label="学生ID" />
      <el-table-column prop="verifiedMobile"
                       align="center"
                       label="手机" />
      <el-table-column prop="truename"
                       align="center"
                       label="用户名" />
      <el-table-column prop="count"
                       align="center"
                       label="反馈题目总数" />
      <el-table-column prop="count2"
                       align="center"
                       label="有效反馈数" />
      <el-table-column prop="name"
                       align="center"
                       width="200"
                       label="操作">
        <template slot-scope="{row}">
          <div class="operyion_btn_icon">
            <el-tooltip effect="dark"
                        content="查看详情"
                        placement="top-start">
              <img src="@/assets/icons/icon-查看@2x.png"
                   @click="showForm(row)"
                   alt="">
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />
  </div>
</template>

<script>
export default {
  data () {
    return {
      tableData: [],
      options: [],
      form: {
        edu_user_id: '',
        edu_user_name: '',
        begin_time: '',
        end_time: '',
        keyword: ''
      },
      page: 1,
      size: 10,
      totalElements: 0,
      all_count: ''

    }
  },
  mounted () {
  },
  methods: {
    getSummaries () {
      const sums = [];
      sums[0] = ''
      sums[1] = ''
      sums[2] = ''
      sums[3] = ''
      sums[4] = this.all_count
      sums[5] = ''

      return sums;
    },
    search () {
      this.$refs.pagination.toFirstPage()
    },
    initData (page, limit) {
      let form = JSON.parse(JSON.stringify(this.form))
      form.page = page
      form.limit = limit
      this.$http({
        url: '/api/v2/stats/question_report',
        method: 'get',
        params: form
      }).then(res => {
        this.tableData = res.data.list
        this.all_count = res.data.all_count
        this.totalElements = res.data.count
      })
    },
    showForm (row) {
      let form = {
        begin_time: this.form.begin_time,
        end_time: this.form.end_time,
        user_id: row.id
      }

      this.$router.push('/feedback_list?form=' + JSON.stringify(form))
    },
    exportExcel () {
      let form = {
        begin_time: this.form.begin_time,
        end_time: this.form.end_time,
        keyword: this.form.keyword
      }
      this.$http({
        url: '/api/v2/stats/question_error_export',
        method: 'get',
        responseType: "blob",
        params: form
        // params: {
        //   keyword: this.form.keyword
        // }
      }).then(res => {
        console.log('res', res);
        const a = document.createElement("a")
        a.href = window.URL.createObjectURL(res.data)
        a.download = `反馈统计${this.form.begin_time}${'-' + this.form.begin_time}.xlsx`
        a.click()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>